import styles from './UsuarioFormEdit.module.css';
import Input from '../form/Input';
import SubmitBtn from '../form/SubmitBtn';
import SelectMultiple from '../form/SelectMultiple';
// import Select from '../form/Select';

import {useState} from 'react'


function UsuarioForm({handleSubmit, btnText, showPassForm, username, userData}) {
  
    const [usuario, setUsuario] = useState(userData || {})
    const [senha, setSenha] = useState()
    const [reSenha, setReSenha] = useState()
    const [statusSelecionado, setStatusSelecionado] = useState(userData.DT_EXCLUSAO !== null ? 'N' : 'S')

    const statusAtual = [
        {'label' : 'ATIVO',    'value' : 'S'},
        {'label' : 'INATIVO',    'value' : 'N'}
    ]

    if (usuario) {
        usuario.tx_nome = usuario.TX_NOME;
        usuario.tx_email = usuario.TX_EMAIL;
        usuario.cd_usuario = usuario.CD_USUARIO;
    }
    if (username)
    usuario.tx_nome = username;
   
    const submit = (e) => {
        e.preventDefault();
        let formData = {};
        let erros = [];

        if ( usuario.tx_nome !== undefined && usuario.tx_nome.length > 5) 
            formData.TX_NOME       = usuario.tx_nome;
        else erros.push("Preencher o nome");
        

        formData.ID             = usuario.ID
        if ( senha == reSenha ) {
            formData.TX_SENHA   = senha
            formData.TX_RESENHA = reSenha 
        } else erros.push ("As senhas não coincidem");
      
        if ( senha !== undefined && senha.length < 5 ) 
           erros.push("A senha precisa conter no mínimo 5 caracteres")

        if ( reSenha !== undefined && reSenha.length < 5 ) 
           erros.push("A re-senha precisa conter no mínimo 5 caracteres")

        formData.USR_ATIVO       = statusSelecionado;

        if ( erros.length == 0 )
            handleSubmit(formData);
        else 
            alert(erros); // TODO melhorar apresentação
    }

    function handleChange(e) {
        
        setUsuario({ ...usuario, [e.target.name]: e.target.value.toUpperCase()})
    } 

    function handleSenha(e) {

        setSenha(e.target.value)
    }
    
    function handleReSenha(e) {

        setReSenha(e.target.value)
    }

    const handleStatus = (e) => {
        setStatusSelecionado(e.value !== undefined ? e.value : 'S');
    }
    return (
        <form onSubmit={submit} className={styles.form}>
            <div className={styles.formHorizontal}>
                <div className={styles.leftCol}>
                    <Input handleOnChange={handleChange} name="TX_NOME" type="text" text="Nome" placeholder="Insira seu nome de usuário" value={usuario.TX_NOME ? usuario.TX_NOME : ''}/>
                </div>
                <div className={styles.rightCol}>
                    <Input name="cd_usuario" type="text" text="Código do Usuário" placeholder="Insira seu código de usuário" value={usuario.CD_USUARIO ? usuario.CD_USUARIO : ''}/>
                </div>
            </div>
            <div className={styles.formHorizontal}>
                <div className={styles.leftCol}>
                    <Input name="TX_EMAIL" type="text" text="E-mail" value={usuario.TX_EMAIL ? usuario.TX_EMAIL : ''}/>
                </div>
                <div className={styles.rightCol}>
                    <SelectMultiple text="Status" name="AO_ATIVO" options={statusAtual} handleOnChange={handleStatus} value={statusSelecionado} />
                </div>
            </div>
                <div className={styles.formHorizontal}>
                    <div className={styles.leftCol}>
                        <Input handleOnChange={handleSenha} name="TX_SENHA" type="password" text="Senha" placeholder="Insira sua senha de usuário" value={senha}/>
                    </div>
                    <div className={styles.rightCol}>
                        <Input handleOnChange={handleReSenha} name="TX_SENHA" type="password" text="Confirmar Senha" placeholder="Insira sua senha de usuário" value={reSenha}/>
                    </div>
                </div>
                <SubmitBtn text={btnText}/>
        </form>
    )
}

export default UsuarioForm