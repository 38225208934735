 
import styles                  from './Home.module.css'
import logo                    from '../../img/logo_empresa.png'
import { useEffect, useState } from 'react';
import Loading                 from '../layout/Loading'

function Logout() {
    
    const [removeLoading, setRemoveLoading] = useState(false)

    useEffect(()  => {
 
        localStorage.removeItem('userSession'); 
        setRemoveLoading(false)
        setTimeout(function() {
            window.location.href = '/';
        }, 3000);
    }, []) 

    return (
        <section className={styles.homeContainer}>
            <img src={logo} alt="J&O Software" />
            <h1>VOLTE SEMPRE!</h1>
            {!removeLoading && <Loading/>} 
        </section>
        )
}

export default Logout