import styles from './UsuarioForm.module.css';
import Input from '../form/Input';
import SubmitBtn from '../form/SubmitBtn';

import {useState} from 'react'

function UsuarioForm({handleSubmit, btnText, showPassForm, username, userData}) {
  
    const [usuario, setUsuario] = useState(userData || {})

    if (usuario.TX_NOME) {
        usuario.tx_nome = usuario.TX_NOME;
        usuario.tx_email = usuario.TX_EMAIL;
        usuario.cd_usuario = usuario.CD_USUARIO;
    }

    if (username)
        usuario.tx_nome = username;
   
    const submit = (e) => {
        e.preventDefault();
        let formData = {};
        let erros = [];

        if ( usuario.tx_nome !== undefined && usuario.tx_nome.length > 5) 
            formData.usr_nome       = usuario.tx_nome;
        else erros.push("Preencher o nome");

        if ( !showPassForm ) {
            if ( usuario.cd_usuario !== undefined && usuario.cd_usuario.length > 5) 
                formData.usr_cd_usuario = usuario.cd_usuario;
            else erros.push("Preencher o código de usuário");
        }

        if ( usuario.tx_email !== undefined && usuario.tx_email.length > 5)
            formData.usr_tx_email   = usuario.tx_email;
        else erros.push("Preencher o email");
        
        if ( showPassForm ) {
            if ( showPassForm && usuario.tx_senha !== undefined )
                formData.usr_tx_password = usuario.tx_senha;
            else erros.push("Preencher a senha");
        }

        if ( erros.length == 0 )
            handleSubmit(formData);
        else 
            alert(erros); // TODO melhorar apresentação
    }

    function handleChange(e) {
        
        setUsuario({ ...usuario, [e.target.name]: e.target.value.toUpperCase()})
    } 

    function handleEmail(e) {
        
        setUsuario({ ...usuario, [e.target.name]: e.target.value.toLowerCase()})
    } 

    function handlePassword(e) {

        setUsuario({ ...usuario, [e.target.name]: e.target.value})
    }

    return (
        <form onSubmit={submit} className={styles.form}>
            <Input handleOnChange={handleChange} name="tx_nome" type="text" text="Nome do Usuário:" placeholder="Insira seu nome de usuário" value={usuario.tx_nome ? usuario.tx_nome : ''}/>
            {!showPassForm && (<Input handleOnChange={handleChange} name="cd_usuario" type="text" text="Código do Usuário:" placeholder="Insira seu código de usuário" value={usuario.cd_usuario ? usuario.cd_usuario : ''}/>)}
            <Input handleOnChange={handleEmail} name="tx_email" type="email" text="E-mail do Usuário:" placeholder="Insira seu e-mail de usuário" value={usuario.tx_email ? usuario.tx_email : ''}/>
            {showPassForm && (<Input handleOnChange={handlePassword} name="tx_senha" type="password" text="Senha do Usuário:" placeholder="Insira sua senha de usuário" value={usuario.tx_senha ? usuario.tx_senha : ''}/>)}
            <SubmitBtn text={btnText} />
        </form>
    )
}

export default UsuarioForm