//export const __URL = 'http://server.josoftware.com.br:82/api';
// export const __URL = 'HTTP://192.168.0.103/api-chamados/public_html/api'; 
export const __URL = 'https://api-chamados.josoftware.com.br/api';
export const __PRIORIDADES = [
    {
        'value': 1,
        'label': 'Baixíssima'
    } ,  {
        'value': 2,
        'label': 'Baixa'
    },  {
        'value': 3,
        'label': 'Média'
    },  {
        'value': 4,
        'label': 'Alta'
    },  {
        'value': 5,
        'label': 'Urgente'
    }
];
export default {__URL, __PRIORIDADES};
