 
import styles    from './Home.module.css'
import logo      from '../../img/logo_empresa.png'
import LoginForm from '../login/LoginForm';
import {__URL}   from '../../config.js';
import {useState} from 'react'
import { useLocation } from 'react-router-dom';

function Login({setUserSession, userSession}) {
 
    function setSession( aux ) {
        const tmpSession = localStorage.getItem('userSession') ? 
                           JSON.parse(localStorage.getItem('userSession')) :
                           aux;
        localStorage.setItem('userSession', JSON.stringify(tmpSession));
        return setUserSession(tmpSession)
    }

    const location = useLocation();
    const [message, setMessage] = useState(location.state ? location.state.message : '')
    const [type, setType] = useState(location.state ? location.state.type : 'error')

    function login(user, pass) {
        setMessage('') 
        // return setSession( "xpt" );
        fetch( __URL + "/usuario/dashboard/admin", {
            method: "GET",
            headers : {
                'Content-type': 'application/json',
                'Authorization': `Basic ${window.btoa(`${user}:${pass}`)}`
            },
         })
        .then((resp) => {
            if (resp.status === 200)
                return resp.json() 
            return false
        })
        .then((data) => {
            if ( data !== false ) {
                setSession(data.data.usr)
                return true;
            }
            setMessage('Senha inválida')
            setType('error')
        })
    }
        
    return (
        <section className={styles.homeContainer}>
            <img src={logo} alt="J&O Software" />
            <h2>Sistema de Chamados</h2>
            <h1>SEJA BEM-VINDO</h1>
             <LoginForm handleSubmit={login} message={message} type={type} btnText="LOGIN" />
        </section>
        )
}

export default Login