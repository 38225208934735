import styles from './MotivoTable.module.css'
import EditButton from '../layout/EditButton'
import RemoveButton from '../layout/RemoveButton'

function  MotivoTable({motivos, handleRemove }) {

    const remove = (e) => {
        e.preventDefault()
        handleRemove(e.target.value)
    }

    return (
            <table className={styles.motivosTable}>
                <thead>
                <tr>
                    <th colSpan='2' className={styles.motivosTableIndice}>Ações</th>
                    <th className={styles.motivosTableID}>ID</th>
                    <th className={styles.motivosTableDescricao}>Descrição</th>
                    <th className={styles.motivosTableIndice}>Status</th>
                </tr>
                </thead>
                <tbody>
                {motivos.length > 0 &&
                motivos.map((mtv) => {
                    let status =  mtv.DT_EXCLUSAO != null ? styles.statusInativo : styles.statusAtivo;
                        return (
                        <tr>
                            <td className={status} colSpan={mtv.DT_EXCLUSAO != null ? 2 : 1}>
                                <EditButton to={`/motivo/${mtv.ID}`}/>
                            </td>
                            {mtv.DT_EXCLUSAO == null && (<td className={status}><RemoveButton to={remove}/></td>)}
                            <td className={styles.motivoTableID}>{mtv.ID}</td>
                            <td className={styles.motivoTableDesc}>{mtv.TX_MOTIVO}</td>
                            <td className={styles.motivoTableStatus}>{ mtv.DT_EXCLUSAO != null ? 'Inativo' : 'Ativo' }</td>
                        </tr>
                        )}
                        )
                    }
                </tbody>
            </table>
        )

    }

export default MotivoTable
