import {Link} from 'react-router-dom'
import styles from './ChamadoCard.module.css'
import {BsReplyFill} from 'react-icons/bs'
import PrioridadeCard from './PrioridadeCard'

function ChamadoCard({id, usuarioAutor, usuarioInteracao, dataUltimaInteracao, grupoEmpresa, empresa, dataCriacao, dataFechamento, motivos, prioridadeExterna, prioridadeInterna, responsaveis, usuarioFechamento, SLAInterna, SLAExterna, nomeModulo}) {

    return (
        <div className={styles.chamado_card}>
            <h4>Chamado ID: {id} <div className={styles.chamado_card_title}> {prioridadeInterna ? (<PrioridadeCard prioridade={prioridadeInterna} SLA={SLAInterna}/>) : <span className={styles.noprior}>SEM PRIORIDADE DEFINIDA</span>}</div></h4>
            <p>
                <span>Responsáveis:</span> {responsaveis.length > 0 ? (responsaveis.map((usr) => { return usr.CD_USUARIO; })).join(', ') : 'SEM RESPONSÁVEIS' }
            </p>
            <p>
                <span>Grupo Empresarial:</span> {grupoEmpresa} {empresa}
            </p>
            <p>
                <span>Motivos:</span> { (motivos.map((motivo) => {return motivo.TX_MOTIVO})).join(', ') }
            </p>
            <p>
                <span>Módulo:</span> {nomeModulo.substr(0, 115)}
            </p>
            <p>
                <span>Prioridade do Cliente:</span> <PrioridadeCard prioridade={prioridadeExterna} SLA={SLAExterna}/>
            </p>
            <p>
                <span>Última atividade em:</span> {dataUltimaInteracao} , <span className={styles.ultimaAtividade}>realizada por:</span> {usuarioInteracao}
            </p>
            <p>
                <span>Data de abertura:</span> {dataCriacao} <span className={styles.ultimaAtividade}>realizada por:</span> {usuarioAutor}
            </p>
            <p>
                <span>Data de fechamento:</span> {dataFechamento} <span className={styles.ultimaAtividade}>realizada por:</span> {usuarioFechamento}
            </p>

            <div className={styles.chamado_card_actions}>
                <Link to={`/chamado/${id}`}>
                    <BsReplyFill /> Acessar Chamado
                </Link>
            </div>
        </div>
        )
}

export default ChamadoCard
