import styles from './Chamado.module.css'
// import {MdAttachFile} from 'react-icons/md'
// import {IoDocumentAttachOutline} from 'react-icons/io'
// import {BsReplyFill} from 'react-icons/bs'
import {useParams} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';

import Loading from '../layout/Loading'
import Container from '../layout/Container'
import ChamadoForm from '../project/ChamadoForm'
import PrioridadeCard from '../project/PrioridadeCard'
import Mensagem from '../project/Mensagem'

import {__URL } from '../../config'
import ReplyForm from '../project/ReplyForm'

function Chamado() {
    const {id} = useParams()
    const navigate = useNavigate()

    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))
    const [setRemoveLoading] = useState(false)

    const [chamado, setChamado] = useState([])
     //const [mensagem, setMensagem] = useState([])
    const [debounce, setDebounce] = useState(true)
    const [showChamadoForm, setShowChamadoForm] = useState(false)
    // const [setMensagemForm, setShowMensagemForm] = useState (false)

    useEffect(() => {
        if (!chamado.hasOwnProperty('ID')) {
         fetch( __URL + `/chamado/admin/${id}`, {
            method : "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            },
        })
        .then((resp) => {
            if (resp.status === 200)
                return resp.json()
            return false
        })
        .then((data) => {
            console.log(data)
            if(data !== false) {
                setChamado(data.data)
                setRemoveLoading(true)
            }
        }).catch((err) => {
            console.log('error')
        })
        }
    }, [id, userSession, chamado, setRemoveLoading ])

    function toggleChamadoForm() {
        setShowChamadoForm(!showChamadoForm)
    }

    function editMotivos(formResponse) {

        console.log(formResponse)

        let data = new FormData();
        formResponse.motivos.map((mtv, indice) => {
            data.append(`motivos[${indice}]`, mtv);
            return mtv;
        })

        formResponse.usuarios.map((usr, indice) => {
            data.append(`usuarios[${indice}]`, usr);
            return usr;
        })
        // data.append('motivos[]', formResponse.motivos);
        data.append('chm_prioridade_interna', formResponse.prioridadeInterna)

        fetch( __URL + `/chamado/${formResponse.chamado.ID}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            },
            body: data
        })
        .then((resp) => resp.json())
        .then((data) => {
            setChamado({ ...chamado, 'MOTIVOS': data.data.CHAMADO.MOTIVOS,
                                     'PRIORIDADE_INTERNA': data.data.CHAMADO.chm_prioridade_interna,
                                     'USUARIOS' : data.data.RESPONSAVEIS })
            setShowChamadoForm(false)
        })
        .catch((err) => console.log(err))
    }

    function replyPost(formResponse) {
        let data = (formResponse)

        if ( debounce ) {
            setDebounce(false)
            fetch ( __URL + `/mensagem`, {
                method: 'POST',
                headers: {
                    'Authorization' : `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
                },
                body: data
            })
            .then((resp) => {
                if (resp.status === 200)
                        return resp.json()
                return false
            })
            .then((data) => {
                navigate(`/chamados`, {state:{message : 'Respondido com sucesso!'}});
                //window.location.href = `/chamado/${id}`;
                //TODO Rever para padrão REACT, modificar API
                //navigate(``, {state:{message : 'Respondido com sucesso!'}} )
                return true;

            })
            .catch((err) => {
                setDebounce(true)
                console.log(err)
            })
        } else {
            alert("Já existe uma requisição em andamento, aguarde!")
        }
    }

    function reOpenPost() {

        let formData = new FormData();

        if ( debounce ) {
            setDebounce(false)
            formData.append('id_chamado', chamado.ID)
            formData.append('chm_encerrar', 'R')
            fetch ( __URL + `/mensagem`, {
                method: 'POST',
                headers: {
                    'Authorization' : `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
                },
                body: formData
            })
            .then((resp) => {
                if (resp.status === 200)
                        return resp.json()
                return false
            })
            .then((data) => {
                navigate(`/chamados`, {state:{message :  'Chamado reaberto com sucesso!'}});
                return true;
            })
            .catch((err) => {
                setDebounce(true)
                console.log(err)
            })
        } else {
            alert("Já existe uma requisição em andamento, aguarde!")
        }

    }

    let responsaveis = []
    if ( chamado.ID )
        responsaveis = chamado.USUARIOS.filter((usrFilter) => {return parseInt(usrFilter.ATRIBUICAO) === 3});

    return (<>
        {chamado.ID ? (
            <div className={styles.chamadoDetails}>
                <Container customClass="column">
                    <div className={styles.detailsContainer}>
                        <h1>Chamado: #{chamado.ID}</h1>
                        {chamado.PRIORIDADE_INTERNA ?
                        ( <PrioridadeCard prioridade={chamado.PRIORIDADE_INTERNA}/>)
                        :
                        (<p className={styles.noprior}>Sem prioridade definida!</p>)}

                        {chamado.DT_FINALIZACAO ?
                         (<button type='button' className={styles.btn} onClick={reOpenPost}>Reabrir Chamado</button>)
                         :
                         (<button type='button' className={styles.btn} onClick={toggleChamadoForm}>
                            {!showChamadoForm ? 'Gerenciar Chamado' : 'Fechar'}
                        </button>)}

                        {!showChamadoForm ? (
                            <div className={styles.chamadoInfo}>
                                <p>
                                    <span>Data de Abertura: </span> {chamado.DT_ABERTURA} ({chamado.CD_USUARIO_AUTOR})
                                </p>
                                <p>
                                    <span>Data Última Atividade: </span> {chamado.DT_ULTIMA_ATIVIDADE} {chamado.CD_USUARIO_ULTIMA_ATV ? `(${chamado.CD_USUARIO_ULTIMA_ATV})` : ""}
                                </p>
                                {chamado.DT_FINALIZACAO && (
                                <p>
                                    <span>Data Finalização: </span> {chamado.DT_FINALIZACAO} ({chamado.CD_USUARIO_FINALIZACAO})
                                </p>
                                )}
                                <p>
                                    <span>Módulo:</span> {chamado.TX_MODULO}
                                </p>

                                <p>
                                    <span>TAGs: </span> {chamado.MOTIVOS.length > 0 ? chamado.MOTIVOS.map((mtv) => {return mtv.TX_MOTIVO}).join(', ') : 'NENHUM'}
                                </p>

                                <p>
                                    <span>Responsáveis: </span> {responsaveis.length > 0 ? responsaveis.map((usr) => {return usr.CD_USUARIO}).join(', ') : 'NENHUM'}
                                </p>

                            </div>
                              ) : (
                                <div className={styles.chamadoInfo}>
                                    <ChamadoForm
                                     handleSubmit={editMotivos}
                                     btnText="Atualizar Chamado"
                                     chamadoData={chamado} />
                                </div>
                            )}
                    </div>

                    <h2>Mensagens</h2>
                    <Container customClass="start">
                        {chamado.MENSAGENS.length > 0 &&
                        <div className={styles.chamadoGroupsMessages}>
                            {chamado.MENSAGENS.map((msg) => {
                                return <Mensagem msg={msg} />
                            })}
                        </div>
                        }
                        {(chamado.DT_FINALIZACAO ?
                            <Container>
                                <div className={styles.chamadoFinalizadoTitle}>
                                    <div className={styles.chamadoFinalizadoContent}>
                                        <p>Este chamado foi encerrado por {chamado.CD_USUARIO_FINALIZACAO} em {chamado.DT_FINALIZACAO}.</p>
                                    </div>
                                    <div className={styles.chamadoFinalizadoButton}></div>
                                </div>
                            </Container> : <ReplyForm handleSubmit={replyPost} chamado={chamado} btnText="Enviar" /> )}
                    </Container>
                 </Container>
            </div>
        ) :<Loading />}
    </>)
}

export default Chamado