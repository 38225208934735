import { __PRIORIDADES } from '../../config';

import styles from './PrioridadesTable.module.css';

function  PrioridadesTable({prioridades}) {

    return (
            <table className={styles.motivosTable}>
                <thead>
                <tr>
                    <th className={styles.motivosTableID}>ID</th>
                    <th className={styles.motivosTableDescricao}>Grupo Empresarial</th>
                    <th className={styles.motivosTableDescricao}>Empresa</th>
                    <th className={styles.motivosTableDescricao}>Externa</th>
                    <th className={styles.motivosTableDescricao}>Minutagem</th>
                    <th className={styles.motivosTableDescricao}>Interna</th>
                    <th className={styles.motivosTableDescricao}>Minutagem</th>
                </tr>
                </thead>
                <tbody>
                {prioridades.length > 0 && 
                prioridades.map((prioRegra) => {
                    let prioridadeInternaTexto = prioRegra.PRIORIDADE_INTERNA ? (__PRIORIDADES.filter((p) => { return p.value === parseInt(prioRegra.PRIORIDADE_INTERNA) })).shift().label.toUpperCase(): 'SEM PRIORIDADE';
                    let prioridadeExternaTexto = prioRegra.PRIORIDADE_EXTERNA ? (__PRIORIDADES.filter((p) => { return p.value === parseInt(prioRegra.PRIORIDADE_EXTERNA) })).shift().label.toUpperCase(): 'SEM PRIORIDADE';


                return (
                <tr>
                    <td className={styles.motivoTableID}>{prioRegra.ID}</td>
                    <td className={styles.motivoTableDesc}>{prioRegra.TX_NOME_GRUPO}</td>
                    <td className={styles.motivoTableDesc}>{prioRegra.TX_NOME_EMPRESA}</td>
                    <td className={styles.motivoTableDesc}>{prioridadeExternaTexto}</td>
                    <td className={styles.tableMinutos}>{prioRegra.MINUTOS_INTERNA}</td>
                    <td className={styles.motivoTableDesc}>{prioridadeInternaTexto}</td>
                    <td className={styles.tableMinutos}>{prioRegra.MINUTOS_EXTERNA}</td>
                </tr> 
                )})}
                </tbody>
            </table> 
        )

    }

export default PrioridadesTable
