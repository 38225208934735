import styles from './SubmitBtn.module.css'
function SubmitBtn ({text, className, hideLabel}) {

    let classes = `${styles.btn} `;
    classes += className !== undefined ? className : null;
    return (
        <div className={styles.form_control}>
            {hideLabel ? (<></>) : (<label>&nbsp;</label>)}
            <button className={`${classes}`}>{text}</button>
        </div>
    )
}

export default SubmitBtn