import {Link} from 'react-router-dom'
import styles from './UsuarioCard.module.css'
import {BsPencil} from 'react-icons/bs'
import {MdOutlinePersonAddDisabled} from 'react-icons/md'

function  UsuarioCard({id, nomeUsuario, codigoUsuario, emailUsuario, statusUsuario, empresa, ultimoAcesso, handleRemove}) {

    const remove = (e) => {
        e.preventDefault()
        handleRemove(id)
    }

    let activeClass = (statusUsuario === 'Inativo' ? styles.inativo : styles.ativo);

    return (
        <div className={styles.usuario_card}>
            <h4>Usuário ID: {id} <span className={`${styles.usuario_card_title} ${activeClass}`} >{statusUsuario}</span></h4>
            <p>
                <span>Nome do Usuário:</span> {nomeUsuario}
            </p>
            <p>
                <span>Código de Usuário:</span> {codigoUsuario}
            </p>
            <p>
                <span>E-mail:</span> {emailUsuario}
            </p>
            <p>
                <span>Grupo Empresarial:</span> {empresa}
            </p>
            <p>
                <span>Último acesso:</span> {ultimoAcesso}
            </p>
            <div className={styles.usuario_card_actions}>
                <Link to={`/usuario/${id}`}>
                    <BsPencil /> Editar
                </Link>
                <button onClick={remove}>
                    <MdOutlinePersonAddDisabled/> Inativar
                </button>
            </div>
        </div>
        )
}

export default UsuarioCard
