import styles from '../pages/Perfil.module.css'
import { useState } from 'react'
import Input from '../form/Input'
import SubmitBtn from '../form/SubmitBtn'
import Container from '../layout/Container'

function Perfil( {handleSubmit} ) {

    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))
    const [user, setUser] = useState(userSession)

    function submit(e) {

        e.preventDefault();
        let formData = new FormData(e.target);
        formData.append ('TX_NOME' , user);
        handleSubmit(formData);
    }

    function handleChange(e) {

        setUser({ ...user, [e.target.name]: e.target.value.toUpperCase()})
    }

    return (
        <Container customClass="middle">
            <form onSubmit={submit} className={styles.perfil}>
                    <div className={styles.formHorizontal}>
                        <div className={styles.leftCol}>
                            <h1>Edição de Perfil</h1>
                        </div>
                        <div className={styles.rightCol}>
                            <SubmitBtn className={styles.perfilSubmit} text='Atualizar Perfil' hideLabel={true}/>
                        </div>
                    </div>
                    <div className={styles.inputForm}>
                        <Input handleOnChange={handleChange} name="TX_NOME" type="text" text="Nome" placeholder="Insira seu nome de usuário" value={user.TX_NOME ? user.TX_NOME : ''}/>
                    </div>
            </form>
        </Container>
    )
}

export default Perfil