import {useLocation} from 'react-router-dom'

import styles from './Motivos.module.css'

import { useState, useEffect } from 'react'

import Container from '../layout/Container'
import GrupoTable from '../project/GrupoTable'
import Loading from '../layout/Loading'
import {__URL}       from '../../config.js'

function Grupos() {

    const [grupos, setGrupos] = useState([])
    const [removeLoading, setRemoveLoading] = useState(false)
    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))

    const location = useLocation()

   
    useEffect(()  => {
        if (grupos.length === 0) {
        fetch( __URL + '/agrupamento', {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            }
        })
        .then(resp => {
            if (resp.status === 200)
                return resp.json() 
            return false
        })
        .then((data) => {
            if(data !== false) {
                setGrupos(data.data.agrupamentos)
                setRemoveLoading(true)
            }
        }) 
        .catch((err) => {
            console.log(err)})

        }
       
    }, [userSession, setGrupos, setRemoveLoading])
    
    return (
        <div className={styles.pageContainer}>
            <div className={styles.pageTitle}>
                <h1>Grupos x Empresas</h1>
            </div>
            <Container customClass="start">
                <GrupoTable grupos={grupos} /> 
                {!removeLoading && <Loading/>}
                {removeLoading && grupos.length === 0 &&
                    <p>Não existem grupos criados.</p>
                }
            </Container>
        </div>
    )
}

export default Grupos