import styles from './ChamadoTable.module.css'
import { __PRIORIDADES } from '../../config'
import AccessButton from '../layout/AccessButton'
import PrioridadeCard from '../project/PrioridadeCard'

function ChamadoTable({chamados, titleText}) {

    let primeiraData = '';

    return (
            <table className={styles.chamadosTable}>
                <thead>
                {titleText !== undefined && (<tr><th colspan="9">{titleText}</th></tr>)}
                <tr>
                    <th>Ações</th>
                    <th>Nº</th>
                    <th>Prioridade</th>
                    <th>Empresa</th>
                    <th>Autor</th>
                    <th>Última Atividade</th>
                    <th>Fechamento</th>
                    <th>Responsável</th>
                    <th>TAGs</th>
                </tr>
                </thead>
                <tbody>
                {chamados.map((chamado) => {
                    let prioridadeTexto = chamado.PRIORIDADE_INTERNA ? (__PRIORIDADES.filter((p) => { return p.value === parseInt(chamado.PRIORIDADE_INTERNA) })).shift().label.toUpperCase(): 'SEM PRIORIDADE';
                    let responsaveis = (chamado.USUARIOS.filter((usr) => { return parseInt(usr.ATRIBUICAO) === 3 }));
                    let quebraLinha = false;
                    let prioridadeClass = chamado.PRIORIDADE_INTERNA === null ? 'noprior' : `prior-${chamado.PRIORIDADE_INTERNA}`;

                    // let pos = 0;
                    if ( chamado.DT_ABERTURA.split(" ")[0] !== primeiraData ) {
                        quebraLinha = true;
                        primeiraData = chamado.DT_ABERTURA.split(" ")[0]; 
                        // pos = chamadosSemResp.push(primeiraData);
                    }

                    // if (responsaveis.length == 0)

                    let labelResponsavel = responsaveis.length > 0 ? (`${responsaveis[0].CD_USUARIO}` + (responsaveis.length > 1 ? ` (${responsaveis.length})` : '' ) ) : 'SEM RESPONSÁVEL'

                    return (<>
                            {quebraLinha &&
                            (
                            <tr className={styles.quebraLinha}>
                                <td colSpan="9">Data de Abertura: {primeiraData} </td>
                            </tr>)}
                        <tr >
                            <td>
                                <AccessButton to={`/chamado/${chamado.ID}`}/>
                            </td>
                            <td>{chamado.ID}</td>
                            <td className={`${styles.chamadoHeadPrioridade} ${styles[prioridadeClass]}`}>{prioridadeTexto} <br/>{chamado.SLA_INTERNA_STATUS}</td>
                            <td className={styles.chamadoHeadEmpresas}>{chamado.EMPRESA_REQUISITANTE}</td>
                            <td className={styles.chamadoHeadUsrAutor}>{chamado.CD_USUARIO_AUTOR} <br></br> <PrioridadeCard prioridade={chamado.PRIORIDADE_EXTERNA} SLA={chamado.SLA_EXTERNA_STATUS} /></td>
                            <td className={styles.chamadoHeadDtAtv}> {chamado.CD_USUARIO_ULTIMA_ATV}<br></br>{chamado.DT_ULTIMA_ATIVIDADE}</td>
                            <td className={styles.chamadoHeadDtFchm}>{chamado.DT_FINALIZACAO ? chamado.DT_FINALIZACAO.substr(0, 10) : ''}</td>
                            <td className={styles.chamadoHeadResponsaveis}>{labelResponsavel}</td>
                            <td className={styles.chamadoHeadTags}>
                                {(chamado.MOTIVOS.map((motivo) => {return motivo.TX_MOTIVO}).join(', '))}
                            </td>
                        </tr>
                    </>)
                })}
                </tbody>

        </table>
        )
}

export default ChamadoTable


///     