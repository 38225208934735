import styles from './MotivoForm.module.css';
import Input from '../form/Input';
import SubmitBtn from '../form/SubmitBtn';
import SelectMultiple from '../form/SelectMultiple';

import {useState} from 'react'

function MotivoForm({handleSubmit, btnText, motivoData}) {

    const [motivo, setMotivo] = useState(motivoData || {})
    //const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))

    const submit = (e) => {
        e.preventDefault();
        handleSubmit(motivo);
    }

    function handleChange(e) {
        setMotivo({ ...motivo, [e.target.name]: e.target.value })
    }

    function handleObrigatorio(e) {
        setMotivo({ ...motivo, ['AO_OBRIGATORIO'] : e.value})
    }

    const obrigatorioOptions = [ {'value' : 'S' , 'label' : 'Sim'} , {'value' : 'N' , 'label' : 'Não'}]

    return (
        <form onSubmit={submit} className={styles.form}>
            <Input handleOnChange={handleChange} name="TX_MOTIVO" type="text" text="Motivo" placeholder="Insira o nome do motivo" value={motivo.TX_MOTIVO ? motivo.TX_MOTIVO : ''}/>
            <SelectMultiple options={obrigatorioOptions} name="AO_OBRIGATORIO" placeholder="Este é um motivo obrigatório?" isMulti={false} value={[motivo.AO_OBRIGATORIO ? motivo.AO_OBRIGATORIO : '']} text="Obrigatório?" handleOnChange={handleObrigatorio} type=""/>
            <SubmitBtn text={btnText} />
        </form>
    )
}

export default MotivoForm