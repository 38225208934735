import {useLocation} from 'react-router-dom'

import styles from './Motivos.module.css'

import { useState, useEffect } from 'react'

import Message from '../layout/Message'
import Container from '../layout/Container'
import MotivoTable from '../project/MotivoTable'
import Loading from '../layout/Loading'
import {__URL}       from '../../config.js'
import LinkButton from '../layout/LinkButton'

function Motivos() {

    const [motivos, setMotivos] = useState([])
    const [removeLoading, setRemoveLoading] = useState(false)
    const [message, setMessage] = useState()
    const [type, setMessageType] = useState('success')
    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))

    const location = useLocation()

    useEffect(()  => {
        fetch( __URL + '/motivo', {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            }
        })
        .then(resp => {
            if (resp.status === 200)
                return resp.json()
            return false
        })
        .then((data) => {
            if(data !== false) {
                setMotivos(data.data)
                setRemoveLoading(true)
            }
        })
        .catch((err) => {
            console.log(err)})
    }, [userSession])

    function removerMotivo(id) {
        fetch( __URL + `/motivo/${id}`, {
            method : 'DELETE',
            headers : {
                'Content-Type' : 'application/json',
                'Authorization' : `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            },
        })
        .then((resp) => {
            if (resp.status === 200)
                return resp.json()
            return false
        })
        .then((data) => {
            console.log(data)
            if(data !== false) {
                setMotivos( motivos.filter( mtv => mtv.ID !== id ))
                setMessage("Motivo removido com sucesso")
                setRemoveLoading(true)
            }
        }).catch((err) => {
            console.log('error')
        })
    }


    if ( location.state ) {
        //console.log(location);
        //setMessage(location.state.message ? location.state.message : '')
        //setMessageType(location.state.type ? location.state.type : 'success')
    }

    return (
        <div className={styles.pageContainer}>
            <div className={styles.pageTitle}>
                <h1>Motivos</h1>
                <LinkButton to="/motivo/criar" text="Criar Motivo" />
            </div>
            {message && <Message msg={message} type={type}/>}
            <Container customClass="start">
                {motivos.length > 0 && (<MotivoTable motivos={motivos} handleRemove={removerMotivo}/>)}
                {!removeLoading && <Loading/>}
                {removeLoading && motivos.length === 0 &&
                    <p>Não existem motivos criados.</p>
                }
            </Container>
        </div>
    )
}

export default Motivos