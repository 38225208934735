import styles from './InputFile.module.css'

function InputFile({text, name, placeholder, handleOnChange, value, multiple}) {
    return (
        <div className={styles.form_control}>
            <label htmlFor={name}>{text}</label>
            <input 
            type='file'
            name={`${name}[]`} 
            placeholder={placeholder} 
            id={name} 
            onChange={handleOnChange} 
            value={value} 
            multiple/>
        </div>
    )
}

export default InputFile