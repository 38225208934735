import ReCAPTCHA from 'react-google-recaptcha';

function Recaptcha({ handleChange}) {

    function validate(e) {
        handleChange(e)
    }

        return (
            <ReCAPTCHA
                sitekey="6LdHI2oUAAAAACInGxYN2EwWnpwwIdpnPBp2kLw_"
                onChange={validate}
            />
        )
    }
export default Recaptcha