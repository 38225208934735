import styles from './MotivoForm.module.css';
import Input from '../form/Input';
import SubmitBtn from '../form/SubmitBtn';
import SelectMultiple from '../form/SelectMultiple';
import { __PRIORIDADES, __URL } from '../../config';
import {useState , useEffect } from 'react'

function RegraForm({handleSubmit, btnText, prioData}) {

    const [regra] = useState(prioData || {})
    const [grupos, setGrupos] = useState([])
    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))
    const [removeLoading, setRemoveLoading] = useState(false)
    const [agrupamentoValue, setAgrupamentoValue] = useState()
    const [prioInterna, setPrioInterna] = useState()
    const [prioExterna, setPrioExterna] = useState()
    const [minutosInterna, setMinutosInterna] = useState()
    const [minutosExterna, setMinutosExterna] = useState()


    useEffect(()  => {
        if (grupos.length === 0) {
        fetch( __URL + '/agrupamento', {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            }
        })
        .then(resp => {
            if (resp.status === 200)
                return resp.json() 
            return false
        })
        .then((data) => {
            if(data !== false) {
                setGrupos(data.data.agrupamentos)
                setRemoveLoading(true)
            }
        })
        .catch((err) => {
            console.log(err)})

        }

    }, [setGrupos, setRemoveLoading])

    const submit = (e) => {
        e.preventDefault();

        let erros = [];

            if ( agrupamentoValue               !== undefined )
                regra.SLA_ID_AGRUPAMENTO       = agrupamentoValue;
            else ( erros.push("Preencha o Agrupamento."));

            if ( minutosInterna                 !== undefined )
                regra.SLA_MINUTOS_INTERNA     = minutosInterna;
            else ( erros.push("Preencha a Minutagem Interna."));

            if ( prioInterna                    !== undefined )
                regra.SLA_PRIORIDADE_INTERNA  = prioInterna;
            else ( erros.push("Preencha a Prioridade Interna."));

            if (minutosExterna                  !== undefined )
                regra.SLA_MINUTOS_EXTERNA     = minutosExterna;
            else ( erros.push("Preencha a Minutagem Externa."));

            if (prioExterna                     !== undefined )
                regra.SLA_PRIORIDADE_EXTERNA  = prioExterna;
            else ( erros.push("Preencha a Prioridade Externa."));

            if ( erros.length == 0 )
                handleSubmit(regra);
            else
                alert(erros);
    }

    function handleAgrupamento(e) {
        setAgrupamentoValue([e.value])
    }

    function handlePrioInterna(e) {
        setPrioInterna(e.value)
    }

    function handlePrioExterna(e) {
        setPrioExterna(e.value)
    }

    function handleMinutosInterna(e) {
        setMinutosInterna(e.target.value)
    }

    function handleMinutosExterna(e) {
        setMinutosExterna(e.target.value)
    }

    return (
        <form onSubmit={submit} className={styles.form}>
            <SelectMultiple options={grupos.map((gp) => { return {'label' : gp.TX_NOME_EMPRESA , 'value' : gp.ID }})} handleOnChange={handleAgrupamento} name="sla_id_agrupamento" text="Nome Empresa" placeholder="Insira o nome do motivo" value={agrupamentoValue}/>
            <SelectMultiple options={__PRIORIDADES} handleOnChange={handlePrioInterna} name="sla_prioridade_interna" text="Prioridade Interna" placeholder="Insira a regra de Prioridade Interna" value={[prioInterna]}/>
            <Input type="number" text="Minutagem Interna" name="sla_minutos_interna" placeholder="Insira a minutagem relacionada a Prioridade Interna" handleOnChange={handleMinutosInterna} value={minutosInterna}/>
            <SelectMultiple options={__PRIORIDADES} handleOnChange={handlePrioExterna} name="sla_prioridade_externa" text="Prioridade Externa" placeholder="Insira a regra de Prioridade Externa" value={[prioExterna]}/>
            <Input type="number" text="Minutagem Externa" name="sla_minutos_externa" placeholder="Insira a minutagem relacionada a Prioridade Externa" handleOnChange={handleMinutosExterna} value={minutosExterna}/>
            <SubmitBtn text={btnText} />
        </form>
    )
}

export default RegraForm