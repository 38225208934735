import {FaFacebook, FaInstagram, FaLinkedinIn} from 'react-icons/fa'
import styles from './Footer.module.css'

function Footer() {
    return (
        <footer className={styles.footer}>
    
            <p className={styles.copyRight}><span>J&O Chamados</span> &copy; 2023</p>
        </footer>
    )
}

export default Footer