import styles from './Select.module.css'
import SelectPicker from 'react-select';

function SelectMultiple({ text, name, options, handleOnChange, value, isMulti, placeholder, disabled}) {
   
    return (
        <div className={styles.form_control}>
            <label htmlFor={name}>{text}</label>
            <SelectPicker 
                    isMulti={(isMulti ? isMulti : false)}
                    onChange={handleOnChange}  
                    name={name} 
                    id={name} 
                    placeholder={(placeholder ? placeholder : text)}
                    options={options}
                    value={value && options.filter((opt)=>{ 
                        value = Array.isArray(value) ? value : new Array(value);
               
                        return (value).includes(opt.value)
                       
                    })}
                    isDisabled={disabled} />
        </div>
    )
}

export default SelectMultiple