import styles from './ChamadoForm.module.css';
import SelectMultiple from '../form/SelectMultiple'
import SubmitBtn from '../form/SubmitBtn';
import {__URL, __PRIORIDADES} from '../../config';

import {useEffect, useState} from 'react'

function ChamadoForm({handleSubmit, btnText, chamadoData}) {

    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))
    const [chamado] = useState(chamadoData || {})
    const [motivos, setMotivos] = useState([]);
    const [motivosSelecionados, setMotivosSelecionados] = useState([]);
    const [prioridadeSelecionada, setPrioridadeSelecionada] = useState([]);
    const [prioridades] = useState(__PRIORIDADES);

    const [responsaveis, setResponsaveis] = useState([])
    const [responsaveisSelecionados, setResponsaveisSelecionados] = useState([])

    useEffect(() => {

    if ( motivos.length === 0 ) {

            fetch( __URL + '/chamado/filter', {
                method: 'GET',
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
                }
            })
            .then(resp => {
                if (resp.status === 200)
                    return resp.json()
                return false
            })
            .then((data) => {
                if(data !== false) {
                    setMotivos(data.data.MOTIVOS)
                    setResponsaveis(data.data.RESPONSAVEIS)
                    setMotivos(data.data.MOTIVOS)
                    setPrioridadeSelecionada([parseInt(chamadoData.PRIORIDADE_INTERNA)])
                    setMotivosSelecionados(chamadoData.MOTIVOS.map((mtv) => { return mtv.ID_MOTIVO }))
                    setResponsaveisSelecionados(chamadoData.USUARIOS.filter((usr) => { return parseInt(usr.ATRIBUICAO) === 3 }).map((usr) => { return usr.ID_USUARIO }))

                }
            })
            .catch((err) => {console.log(err)})
        }

    },[userSession, setMotivosSelecionados, setMotivos, chamadoData, setResponsaveisSelecionados]);

    const submit = (e) => {
        e.preventDefault();
        let formResponse = {
            chamado : chamado,
            motivos : motivosSelecionados,
            prioridadeInterna : prioridadeSelecionada,
            usuarios : responsaveisSelecionados
        };

        let erros = [];

        if ( motivosSelecionados !== undefined && motivosSelecionados.length > 0 )
            motivos.TX_MOTIVO = motivosSelecionados;
        else
            erros.push("Preencha um motivo.");

        if ( erros.length === 0 )
            handleSubmit(formResponse);
        else
            alert(erros);
    }

    const handleMotivo = (e) => {

        setMotivosSelecionados([]);
        let aux = [];

        e.forEach((select) => {
            aux.push(select.value);
        });

        setMotivosSelecionados(aux);

    }

    const handleResponsavel = (e) => {

        setResponsaveisSelecionados([]);
        let aux = [];

        e.forEach((select) => {
            aux.push(select.value);
        });

        setResponsaveisSelecionados(aux);

    }

    const handlePrioridades = (e) => {

        setPrioridadeSelecionada([]);
        let aux = [];
        aux.push(e.value);
        setPrioridadeSelecionada(aux)

    }


    return (
        <form onSubmit={submit} className={styles.form}>
            <div className={styles.select}>
                <SelectMultiple isMulti={true} handleOnChange={handleMotivo} name="motivos" text="Motivos" options={motivos.map((mtv) => {return {'value' : mtv.ID, 'label' : mtv.TX_MOTIVO}})} value={motivosSelecionados}  />
            </div>
            <div className={styles.select}>
                <SelectMultiple isMulti={false} handleOnChange={handlePrioridades} name="chm_prioridade_interna" text="Prioridade" options={prioridades} value={prioridadeSelecionada}/>
            </div>
            <div className={styles.select}>
                <SelectMultiple isMulti={true} handleOnChange={handleResponsavel} name="usuarios" text="Responsável" options={responsaveis.map((resp) => {return {'value' : resp.ID, 'label' : resp.CD_USUARIO}})} value={responsaveisSelecionados} />
            </div>
            <div className={styles.submit}><SubmitBtn text={btnText} /></div>
        </form>
    )
}

export default ChamadoForm