import styles from './Motivo.module.css'

import {useParams} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';

import Loading     from '../layout/Loading'
import MotivoForm  from '../project/MotivoForm'
import {__URL, __}     from '../../config.js'

function Motivo() {

    const {id} = useParams()
    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))
    const navigate = useNavigate()

    const [motivo, setMotivo] = useState([])

    useEffect(() => {
        fetch( __URL + `/motivo/${id}`, {
            method : "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            setMotivo(data.data)
        }).catch((err) => {
            console.log('error')
        })
    }, [id], [userSession])

    function editarMotivo(motivo) {

        let formData = new FormData()
        formData.append('mtv_motivo', motivo.TX_MOTIVO)

        fetch(__URL + `/motivo/${motivo.ID}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            },
            body: formData
        })
        .then((resp) => {
            if (resp.status === 200)
                return resp.json()
            return false
        })
        .then((data) => {
            if(data !== false) {
                navigate('/motivos', {state : {message : `Motivo ${motivo.ID} atualizado com sucesso`, type : 'success'}});
                return true;
            } else {
                navigate('/motivos', {state : {message : `Motivo ${motivo.ID} sofreu falhas na alteração!`, type : 'error'}});
                return false;
            }
        })
        .catch((err) => console.log(err))
    }

    return (<>
        {motivo.ID ? (
            <div className={styles.newProject_container}>
                <h1>Editar Motivo</h1>
                <p>Crie uma pré-definição de motivo a ser inserido no chamado</p>
                <MotivoForm motivoData={motivo} handleSubmit={editarMotivo} btnText="Editar Motivo" />
            </div>
        ) :<Loading />}
    </>)
}

export default Motivo