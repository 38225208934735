import {useNavigate} from 'react-router-dom';
import UsuarioForm from '../project/UsuarioForm';
import styles from './NovoUsuario.module.css';
import {__URL} from '../../config';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


function NovoUsuario({showPassForm}) {

    const {id} = useParams()
    const navigate = useNavigate();
    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))
    const [username, setUsername] = useState('')

    useEffect(() => {
        if (id !== undefined && id.length > 5) {
         fetch( __URL + `/registro/${id}`, {
            method : "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((resp) => {
            if (resp.status === 200)
                return resp.json() 
            return false
        })
        .then((data) => {
            if(data !== false) {
                setUsername(data.data.usr.TX_NOME)
            } else {
                navigate('/', {state:{message : `O convite informado expirou!`}} )
            }
        }).catch((err) => {
            console.log('error')
        })
        }
    }, [id])


    function createPost(formDataParam) {

        let formData = new FormData()
        formData.append('usr_nome', formDataParam.usr_nome);
        formData.append('usr_cd_usuario', formDataParam.usr_cd_usuario);
        formData.append('usr_tx_email', formDataParam.usr_tx_email);
         
        fetch( __URL + "/registro", {
            method: "POST",
            headers : {
                'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            }, 
            body : (formData)
        })
        .then(resp => {
            if (resp.status === 200)
                return resp.json() 
            return false
        })
        .then(data => {
            navigate('/usuarios', {state:{message : `Convite ${data.data.uuid} gerado e enviado para ${data.data.usr_tx_email}!`}} )
        })
        .catch(err => { 
            console.log(err)})
    }

    function updateInvite(formDataParam) {
        let formData = new FormData()
        formData.append('usr_nome', formDataParam.usr_nome);
        formData.append('usr_tx_email', formDataParam.usr_tx_email);
        formData.append('usr_tx_senha', formDataParam.usr_tx_password);

        fetch( __URL + `/registro/${id}`, {
            method: "PATCH", 
            body : (formData) 
        })
        .then(resp => {
            if (resp.status === 200)
                return resp.json() 
            return false
        })
        .then(data => {
            if(data !== false) {
                navigate('/', {state:{message : `A ativação da conta foi realizada com sucesso!`, type : 'success'}})
            } else {
                navigate('/', {state:{message : `O e-mail informado não corresponde ao convite gerado!`}} )
            }
        })
        .catch(err => { 
            console.log(err)})
    }

    return (
        <div className={styles.newProject_container}>
            <h1>Criar Usuário</h1>
            <p>Gerar acesso para um novo usuário de atendimento.</p>
            <UsuarioForm handleSubmit={(showPassForm ? updateInvite : createPost)} username={username} showPassForm={showPassForm} btnText="Criar Usuário" />
        </div>
    )
}

export default NovoUsuario