import {Link} from 'react-router-dom'

import styles from './Navbar.module.css'
import logo from '../../img/costs_logo.png';
import { useState } from 'react';

import Container from './Container'
function Navbar() {

    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))
    const [dropdownActive, setDropDownActive] = useState(false)
    const [dropdownActive2, setDropDownActive2] = useState(false)

    function toggleDropdown() {
        setDropDownActive(!dropdownActive)
    }
    
    function toggleDropdown2() {
        setDropDownActive2(!dropdownActive2)
    }

    return (
        <nav className={styles.navbar}>
        <Container>
            <Link to="/">
                <img src={logo} alt="Home" /> 
            </Link> 

            <ul className={styles.list}>
                <li className={styles.item}><Link to="/chamados">Chamados</Link></li>
                <li className={styles.item}><Link to="/usuarios">Usuários</Link></li>
                <li className={`${styles['item']} ${styles['dropdown']}`} onClick={toggleDropdown2}>Configurações 
                    <ul className={((dropdownActive2 && styles.active) || '')}>
                        <li><Link to="/grupos">Grupos</Link></li>
                        <li><Link to="/motivos">Motivos</Link></li>
                        <li><Link to="/regras">Regras</Link></li>
                    </ul>
                </li> 
                <li className={`${styles['item']} ${styles['dropdown']}`} onClick={toggleDropdown}>{userSession.CD_USUARIO} 
                    <ul className={((dropdownActive && styles.active) || '')}>
                        <li><Link to="/perfil">Perfil</Link></li>
                        <li><Link to="/logout">Sair</Link></li>
                    </ul>
                </li>
            </ul>
        </Container>
        </nav>
    )
}

export default Navbar