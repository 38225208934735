import styles from './InputDate.module.css'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ptBR from 'date-fns/locale/pt-BR'
import { setDefaultLocale } from "react-datepicker"
setDefaultLocale(ptBR)

function InputDate({dateFormat, text, name, placeholderText, handleOnChange, selected}) {
    return (
        <div className={styles.form_control}>
            <label htmlFor={name}>{text}</label>
            <DatePicker
                id={name}
                dateFormat={dateFormat}
                placeholderText={placeholderText}
                onChange={handleOnChange}
                selected={selected}
                />
        </div>
    )
}

export default InputDate