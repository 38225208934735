import styles      from './LoginForm.module.css';
import Input       from '../form/Input';
import SubmitBtn   from '../form/SubmitBtn';
import Message     from '../layout/Message'
import Recaptcha   from './Recaptcha';

import {useState}  from 'react'

function LoginForm({handleSubmit, btnText, message, type}) {

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [recaptchaOk, setRecaptchaOk] = useState(false);

    const submit = (e) => {
        e.preventDefault();
        if ( username && password )
        handleSubmit(username, password);
    }

    function handleChangeUser(e) {
        setUsername(e.target.value);
        e.target.value = ("" + e.target.value).toLowerCase();
    }

    function handleChangePass(e) {
        setPassword(e.target.value)
    }

    function handleChangeRecaptcha(e) {

        fetch (`https://www.google.com/recaptcha/api/siteverify?secret=6LdHI2oUAAAAAKO0IfMqU_xvBx6__N9hD39d5-2w&response=${e}`,
            {
                method: 'GET',
                mode: 'no-cors',
            })
        .then((data) => {
            let aprovar = true;
            setRecaptchaOk(aprovar);
            return aprovar;
        })
        .catch((err) => console.log('err'));
        console.log(recaptchaOk);
    }
    let typeForm = type !== undefined ? type : 'success'

    return (
        <form onSubmit={submit} className={styles.form}>
            <Input handleOnChange={handleChangeUser} name="username" type="email" text="E-mail" placeholder="E-MAIL"/>
            <Input handleOnChange={handleChangePass} name="password" type="password" text="Senha" placeholder="SENHA"/>
            <Recaptcha handleChange={handleChangeRecaptcha}/>
            <a href='/forgotpass' className={styles.forgotPass}>Esqueci minha senha</a>
            <SubmitBtn text={btnText} />
            <Message msg={message} type={typeForm}/>
        </form>
    )
}

export default LoginForm