import styles from './GrupoTable.module.css'
 
function  GrupoTable({grupos}) {
  

    return (
        <table className={styles.table}>
            <tr>
                <th className={styles.tableDescricao}>Grupo</th>
                <th className={styles.tableDescricao}>Empresa</th>
                <th className={styles.tableIDHead}>Qtd. Chamados</th>
            </tr>
            {grupos.length > 0 && 
            grupos.map((grupo) => (
                <tr>
                    <td className={styles.tableDesc}>{grupo.TX_NOME_GRUPO}</td> 
                    <td className={styles.tableDesc}>{grupo.TX_NOME_EMPRESA}</td> 
                    <td className={styles.tableID}>{grupo.QTD}</td> 
                </tr>
            ))}
        </table> 
        )

    }

export default GrupoTable
