import {Link} from 'react-router-dom'
import { BsReplyAllFill } from 'react-icons/bs'
import styles from './AccessButton.module.css'

function AccessButton({to}) {
    return (
        <Link className={styles.btn} to={to}>
            <BsReplyAllFill/>
        </Link>
    )
}

export default AccessButton