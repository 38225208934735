import styles                from './Usuario.module.css'

import {useParams}           from 'react-router-dom'
import {useState, useEffect} from 'react'
import {useNavigate}         from 'react-router-dom';

import Loading               from '../layout/Loading'

import UsuarioFormEdit       from '../project/UsuarioFormEdit'
import {__URL, __}           from '../../config.js'

function Usuario() {

    const {id} = useParams()
    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))
    const navigate = useNavigate()

    const [usuario, setUsuario] = useState({})
 
    useEffect(() => {
        fetch( __URL + `/usuario/${id}`, {
            method : "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            setUsuario(data.data)
            console.log(data.data)
        }).catch((err) => {
            console.log('error')
        })
    }, [id], [userSession])
  
    function editarUsuario(usuario) {
  
        let formData = new FormData()
        formData.append('usr_nome',       usuario.TX_NOME)
        formData.append('usr_tx_senha',   usuario.TX_SENHA)
        formData.append('usr_tx_resenha', usuario.TX_RESENHA)
        formData.append('usr_ativo',      usuario.USR_ATIVO)
        
        fetch(__URL + `/usuario/${usuario.ID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            },
            body: formData
        })
        .then((resp) => {
            if (resp.status === 200)
                return resp.json() 
            return false 
        })
        .then((data) => {
            if(data !== false) {
                navigate('/usuarios', {state : {message : `Usuario ${usuario.ID} atualizado com sucesso`, type : 'success'}});
                return true;
            } else {
                navigate('/usuarios', {state : {message : `Usuario ${usuario.ID} sofreu falhas na alteração!`, type : 'error'}});
                return false;
            }
        })
        .catch((err) => console.log(err))
    }
 
    return (<>
        {usuario.ID ? (
            <div className={styles.newProject_container}>
                <h1>Editar Usuario</h1>
                <p>Realize a Edição do Usuário abaixo</p>
                {!isNaN(id) && <UsuarioFormEdit userData={usuario} handleSubmit={editarUsuario}/>}
            </div>
        ) :<Loading />}
    </>)
}

export default Usuario