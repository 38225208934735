import styles from './Checkbox.module.css'

function Checkbox({name, type, value, text, handleChangeEncerrar }) {
    

    function handleChangeEnc(el) {
        handleChangeEncerrar(el.target)
    }

    return (
        <div className={styles[type]}>
            <label  >{text}</label>
            <input type='checkbox' name={name} id={name} onClick={handleChangeEnc} value={value}  />
        </div>
    )

}

export default Checkbox