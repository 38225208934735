import Input from "../form/Input"
import SelectMultiple from '../form/SelectMultiple';
import SubmitBtn from '../form/SubmitBtn'; 
import InputDate from "../form/InputDate";

import styles from './ChamadoFilter.module.css'


import React, { useState, useEffect } from "react"
import { __URL, __PRIORIDADES } from '../../config'


function ChamadoFilter({handleSubmit}) {

    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))

    const [statusSelecionadoFilter, setStatusSelecionadoFilter] = useState('A')
    const [possuiResponsavelFilter, setPossuiResponsavelFilter] = useState('T')

    const [prioridadeInternaFilter, setPrioridadeInternaFilter] = useState()
    const [prioridadeExternaFilter, setPrioridadeExternaFilter] = useState()
    const [prioridades] = useState([...__PRIORIDADES, {'value' : undefined, 'label' : 'Todas'}])
    
    const [motivosSelecionadosFilter, setMotivosSelecionadosFilter] = useState()
    const [motivos, setMotivos] = useState([])

    const [autoresSelecionadosFilter, setAutoresSelecionadosFilter] = useState([undefined])
    const [autores, setAutores] = useState([])
 
    const [responsaveisSelecionadosFilter, setResponsaveisSelecionadosFilter] = useState([userSession.ID])
    const [responsaveis, setResponsaveis] = useState([])

    const [dataInicialFilter, setDtInicialFilter] = useState()
    const [dataFinalFilter, setDtFinalFilter] = useState()

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [ticketFilter, setTicketFilter] = useState()

    const [conteudoFilter, setConteudoFilter] = useState()

    const statusOptions = [
        {'label' : 'ABERTO',    'value' : 'A'},
        {'label' : 'ENCERRADO', 'value' : 'E'},
        {'label' : 'TODOS',     'value' : 'T'},
    ]
 
    const possuiResponsavelOptions = [
        {'label' : 'SIM', 'value' : 'S'},
        {'label' : 'NÃO', 'value' : 'N'},
        {'label' : 'SIM E NÃO', 'value' : 'T'},
    ]
    useEffect(()  => {

        fetch( __URL + '/chamado/filter', {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            }
        })
        .then(resp => {
            if (resp.status === 200)
                return resp.json() 
            return false
        })
        .then((data) => {
            if(data !== false) {
                data.data.AUTORES.unshift({'ID' : undefined, 'CD_USUARIO' : 'TODOS'})
                data.data.RESPONSAVEIS.unshift({'ID' : undefined, 'CD_USUARIO' : 'TODOS'})
                setMotivos(data.data.MOTIVOS)
                setAutores(data.data.AUTORES)
                setResponsaveis(data.data.RESPONSAVEIS)
            }
        })
        .catch((err) => {console.log(err)})
    }, [userSession, setMotivos])

    function submit(evt) {
        evt.preventDefault()

        let formData = {}
        formData.status             = statusSelecionadoFilter;
        formData.prioridade_interna = prioridadeInternaFilter;
        formData.prioridade_externa = prioridadeExternaFilter;
        formData.motivos            = motivosSelecionadosFilter;
        formData.autores            = autoresSelecionadosFilter;
        formData.possui_resp        = possuiResponsavelFilter;
        if ( possuiResponsavelFilter != 'N')
            formData.responsaveis       = responsaveisSelecionadosFilter;

        console.log(conteudoFilter)
        if (conteudoFilter && conteudoFilter.length < 5)
            alert("Preencha pelo menos 5 caracteres no conteúdo"); // TODO Melhorar Alerta
        
        if (conteudoFilter && conteudoFilter.length >= 5)
            formData.conteudo           = conteudoFilter;

        formData.data_inicial       = dataInicialFilter;
        formData.data_final         = dataFinalFilter;
        formData.numero_ticket      = ticketFilter;
        handleSubmit(formData)                      
    }

    function handleChangeAutor(e) {
        setAutoresSelecionadosFilter([e.value])
    }

    function handleChangeResponsavel(e) {
        setResponsaveisSelecionadosFilter([e.value])
    }

    const handleMotivo = (e) => {
        
        setMotivosSelecionadosFilter([]);
        let aux = [];

        e.forEach((select) => {
           aux.push(select.value);
        });

        setMotivosSelecionadosFilter(aux); 

    }

    const handlePrioridadeInterna = (e) => {
        setPrioridadeInternaFilter([])
        let aux = []; 
        aux.push(e.value); 
        setPrioridadeInternaFilter(aux)
    }

    const handlePrioridadeExterna = (e) => {
        setPrioridadeExternaFilter([])
        let aux = []; 
        aux.push(e.value); 
        setPrioridadeExternaFilter(aux)
    }
    
    const handleChangeStatus = (e) => {
        setStatusSelecionadoFilter(e.value !== undefined ? e.value : 'A')
    }

    const handleChangeDtInicial = (e) => { 
        if (e) {
            let dataFormatada = ((e.getDate() )).toString().padStart(2, '0') + "/" + ((e.getMonth() + 1)).toString().padStart(2, '0') + "/" + e.getFullYear(); 
        
            setStartDate(e)
            setDtInicialFilter(dataFormatada)
        } else {
            setStartDate(e)
            setDtInicialFilter()
        }

    }

    const handleChangeDtFinal = (e) => {
        if (e) {
            let dataFormatada = ((e.getDate() )).toString().padStart(2, '0') + "/" + ((e.getMonth() + 1)).toString().padStart(2, '0') + "/" + e.getFullYear();
            
            setEndDate(e)
            setDtFinalFilter(dataFormatada)
       } else {
            setEndDate(e)
            setDtFinalFilter()
       }
    }

    const handleChangeTicket = (e) => {
        setTicketFilter(e.target.value);
    }

    const handleChangeWithRespFilter = (e) => {
        setPossuiResponsavelFilter(e.value) 
    }

    const handleConteudo = (e) => {
        setConteudoFilter(e.target.value)
    }

    return ( 
             <form onSubmit={submit} className={`${styles.formHorizontal} ${styles.formHorizontalFirst}`}>
                <div className={styles.leftCol}>
                    <div className={styles.formHorizontal}>
                        <div className={styles.leftCol}>
                            <SelectMultiple isMulti={false} handleOnChange={handleChangeWithRespFilter} name="filter_com_responsavel" text="Possui responsável?" options={possuiResponsavelOptions} value={possuiResponsavelFilter} />
                        </div>
                        <div className={styles.rightCol}>
                            <SelectMultiple disabled={possuiResponsavelFilter == 'N'} isMulti={false} handleOnChange={handleChangeResponsavel} name="filter_responsavel" text="Responsável" options={responsaveis.map((resp) => {return {'value' : resp.ID, 'label' : resp.CD_USUARIO}})} value={responsaveisSelecionadosFilter} />
                        </div>
                    </div>
                    <SelectMultiple isMulti={false} handleOnChange={handleChangeAutor} name="filter_autor" text="Autor" options={autores.map((autor) => {return {'value' : autor.ID, 'label' : `${autor.CD_USUARIO} ` + (autor.QTD !== undefined ? `(${autor.QTD} CHAMADOS)` : '')}})} value={autoresSelecionadosFilter} />
                    <div className={styles.formHorizontal}>
                        <div className={styles.leftCol}>
                            <InputDate
                                name='filter_data_inicial'
                                text='Data Inicial'
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD/MM/AAAA"
                                handleOnChange={handleChangeDtInicial}
                                selected={startDate}
                             />
                        </div>
                        <div className={styles.rightCol}>
                            <InputDate
                                name='filter_data_final'
                                text='Data Final'
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD/MM/AAAA"
                                handleOnChange={handleChangeDtFinal}
                                selected={endDate}
                             />
                        </div>
                    </div>
                </div>
                <div className={styles.rightCol}>
                    <div className={styles.formHorizontal}>
                        <div className={styles.leftCol}>
                            <SelectMultiple isMulti={false} handleOnChange={handlePrioridadeInterna} name="filter_prioridade_interna" text="Prioridade Interna (J&O)" options={prioridades}  />
                        </div>
                        <div className={styles.rightCol}>
                            <SelectMultiple isMulti={false} handleOnChange={handlePrioridadeExterna} name="filter_prioridade_externa" text="Prioridade Externa (Cliente)" options={prioridades}  />
                        </div>
                    </div>
                    <Input text='Conteúdo' type='text' name='filter_conteudo' placeholder={'INFORME UMA PARTE DA MENSAGEM'} handleOnChange={handleConteudo} value={conteudoFilter} />

                    <div className={styles.formHorizontal}>
                        <div className={styles.leftCol}>
                            <SelectMultiple isMulti={false} handleOnChange={handleChangeStatus} name="filter_status" text="Status Chamado" options={statusOptions} value={statusSelecionadoFilter}/>
                        </div>
                        <div className={styles.rightCol}>
                            <SelectMultiple isMulti={true} handleOnChange={handleMotivo} name="filter_motivo" text="Motivos" options={motivos.map((mtv) => {return {'value' : mtv.ID, 'label' : mtv.TX_MOTIVO}})}  />
                        </div>
                    </div>
                    <div className={styles.formHorizontal}>
                        <div className={styles.leftCol}>
                            <Input handleOnChange={handleChangeTicket} name="filter_ticket" type="text" text="N° Chamado" placeholder="# Ticket" value={ticketFilter} />
                        </div>
                        <div className={styles.rightCol}>
                            <SubmitBtn text="Pesquisar" />
                        </div>
                    </div>

                </div>

             </form>
        )
}

export default ChamadoFilter
