import { MdLabel } from 'react-icons/md';
import styles from './PrioridadeCard.module.css'
import { __PRIORIDADES } from '../../config';

function  PrioridadeCard({prioridade, SLA}) {
 
    let prioridadeTexto = (__PRIORIDADES.filter((p) => { return p.value === parseInt(prioridade) })).shift().label.toUpperCase(); 
    return (
        <div className={`${styles.prioridadeCard} ${styles[`prior-${prioridade}`]}`}> <MdLabel/> PRIORIDADE {prioridadeTexto} — {SLA}</div>
    )
}

export default PrioridadeCard