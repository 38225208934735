import {useNavigate} from 'react-router-dom';
import RegraForm from '../project/RegraForm';
import styles from './NovaRegra.module.css';
import {__URL} from '../../config';
import { useEffect, useState } from 'react';

function NovaRegra() {

    const navigate = useNavigate();
    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))

    function createPost(regra) {

            let data = new FormData()
            data.append('sla_id_agrupamento', regra.SLA_ID_AGRUPAMENTO)
            data.append('sla_minutos_interna', regra.SLA_MINUTOS_INTERNA)
            data.append('sla_prioridade_interna', regra.SLA_PRIORIDADE_INTERNA)
            data.append('sla_minutos_externa', regra.SLA_MINUTOS_EXTERNA)
            data.append('sla_prioridade_externa', regra.SLA_PRIORIDADE_EXTERNA)

            fetch( __URL + "/prioridade", {
                method: "POST",
                headers : {
                    'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
                },
                body : data
            })
            .then(resp => {
                if (resp.status === 200)
                    return resp.json()
                return false
            })
            .then(resp => {
            navigate('/regras', {state:{message : 'Regra criada com sucesso!'}} )
            })
            .catch(err => {
                console.log(err)})
            }

        return (
            <div className={styles.newProject_container}>
                <h1>Criar Regra</h1>
                <p>Crie uma pré-definição de regra de prioridades a ser utilizada pelos chamados futuros</p>
                <RegraForm handleSubmit={createPost} btnText="Criar Regra" />
            </div>
        )
    }
export default NovaRegra