import {Link} from 'react-router-dom'
import { BsFillTrashFill } from 'react-icons/bs'
import styles from './RemoveButton.module.css'

function RemoveButton({to}) {
    return (
        <Link className={styles.btn} to={to}>
            <BsFillTrashFill/>
        </Link>
    )
}

export default RemoveButton