import {useLocation} from 'react-router-dom'

import styles from './Prioridades.module.css'

import { useState, useEffect } from 'react'

import Message from '../layout/Message'
import Container from '../layout/Container'
import PrioridadesTable from '../project/PrioridadesTable'
import Loading from '../layout/Loading'
import {__URL}       from '../../config.js'
import Button from '../form/Button'
import LinkButton from '../layout/LinkButton'

function Prioridades() {

    const [prioridadeRegra, setPrioridadeRegra] = useState([])
    const [removeLoading, setRemoveLoading] = useState(false)
    const [message, setMessage] = useState()
    const [type] = useState('success')
    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))

    const location = useLocation()

    useEffect(()  => {
        fetch( __URL + '/prioridade', {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            }
        })
        .then(resp => {
            if (resp.status === 200)
                return resp.json() 
            return false
        })
        .then((data) => {
            if(data !== false) {
                setPrioridadeRegra(data.data.REGRAS)
                setRemoveLoading(true)
            }
        }) 
        .catch((err) => {
            console.log(err)})
    }, [userSession])
     
    if ( location.state ) {
        //console.log(location);
        //setMessage(location.state.message ? location.state.message : '')
        //setMessageType(location.state.type ? location.state.type : 'success')
    }

    return (
        <div className={styles.pageContainer}>
            <div className={styles.pageTitle}>
                <h1>Prioridades</h1>
                <LinkButton to="/regras/criar" text="Criar Regra"/>
            </div>
            {message && <Message msg={message} type={type}/>}
            <Container customClass="start">
                {prioridadeRegra.length > 0 && (<PrioridadesTable prioridades={prioridadeRegra}/>)}
                {!removeLoading && <Loading/>}
                {removeLoading && prioridadeRegra.length === 0 &&
                    <p>Não existem prioridades criadas.</p>
                }
            </Container>
        </div>
    )
}

export default Prioridades