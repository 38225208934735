import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
 

import Navbar from './components/layout/Navbar'
import Container from './components/layout/Container'
import Footer from './components/layout/Footer'

// import Home from './components/pages/Home'
import Chamado from './components/pages/Chamado'
import Usuario from './components/pages/Usuario'
import Usuarios from './components/pages/Usuarios'
import Motivo from './components/pages/Motivo'
import Motivos from './components/pages/Motivos'
import Grupos from './components/pages/Grupos'
import NovoMotivo from './components/pages/NovoMotivo'
import Chamados from './components/pages/Chamados'
import Contact from './components/pages/Contato'
import Perfil from './components/pages/Perfil'
import Prioridades from './components/pages/Prioridades'
import NovaRegra from './components/pages/NovaRegra'

import Login from './components/pages/Login'
import Logout from './components/pages/Logout'

import { useState } from 'react'
import NovoUsuario from './components/pages/NovoUsuario'
 
function App() {
  
  const [userSession, setUserSession] = useState(false)

  if(!userSession && !localStorage.getItem('userSession')) {
    return ( 
    <Router>
    
        <Container userSession={userSession} customClass="min-height">
          
          <Routes>
            <Route path="/"               element={<Login setUserSession={setUserSession} userSession={userSession} />} />
            <Route path="/convite/:id"    element={<NovoUsuario showPassForm={true} />} />
            <Route path="*"               element={<Login setUserSession={setUserSession} userSession={userSession} />} />
          </Routes>
          </Container>
    </Router>)
  }

  return (
    <Router>
      <Navbar />
        <Container userSession={userSession} customClass="min-height">
          <Routes>
            {/* <Route path="/"               element={<Home userSession={userSession} />} /> */}
            <Route path="/"               element={<Chamados />} />
            <Route path="/contato"        element={<Contact />} />

            <Route path="/usuarios"       element={<Usuarios _viewPort='list'/>} />
            <Route path="/usuario/:id"    element={<Usuario />} />
            <Route path="/usuarios/criar" element={<NovoUsuario />} />

            <Route path="/perfil"         element={<Perfil />} />
            
            <Route path="/chamado/:id"    element={<Chamado />} />
            <Route path="/chamados"       element={<Chamados _viewPort='list' />} />

            <Route path="/motivos"        element={<Motivos />} />
            <Route path="/motivo/:id" 	  element={<Motivo />} />
            <Route path="/motivo/criar"   element={<NovoMotivo />} />

            <Route path="/regras"         element={<Prioridades />} />
            <Route path="/regras/criar"   element={<NovaRegra />} />

            <Route path="/grupos"         element={<Grupos />} />

            <Route path="/logout"         element={<Logout />} />
          </Routes>       
        </Container>
      <Footer />
    </Router>
  );
}

export default App;
