import styles from './Mensagem.module.css'
 
import {CgAttachment} from 'react-icons/cg'
import {useState, useEffect} from 'react'
 
 import {__URL, __PRIORIDADES } from '../../config'
import Button from '../form/Button'
 
function Mensagem({msg}) {
 
    const handleAnexo = (e) => {
        window.open(e, '_blank').focus()
    }

    return (
        <div className={styles.chamadoCommentContent}>
            <div className={styles.chamadoCommentPointer}>
                <div className={styles.chamadoCommentAuthor}>
                    {msg.ATRIBUICAO}: {msg.CD_USUARIO} 
                </div>
                <div className={styles.chamadoCommentEmpresa}>
                    Empresa: {msg.TX_NOME_EMPRESA}
                </div>
                <div className={styles.chamadoCommentDate}>
                    Enviado em: {msg.DT_CRIACAO}
                </div>

            </div>
            <div className={styles.chamadoComment} dangerouslySetInnerHTML={{__html: msg.TX_CONTEUDO}}>

            </div>
            <div className={styles.chamadoCommentAttach}>
                {msg.ANEXOS.map((anexo) => {
                    return <Button handleOnClick={handleAnexo} value={`${msg.base_url}/${anexo.TX_NOME}`} text={`Anexo ${anexo.ID}`} icon={<CgAttachment />} />
                })}
            </div>
        </div>
    )

}

export default Mensagem