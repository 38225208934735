import {useLocation} from 'react-router-dom'

import styles from './Chamados.module.css'

import { useState, useEffect } from 'react'

import Message from '../layout/Message'
import Container from '../layout/Container'
import Button from '../form/Button'
import ChamadoCard from '../project/ChamadoCard'
import ChamadoTable from '../project/ChamadoTable'
import ChamadoFilter from '../project/ChamadoFilter'

// import MotivoCard from '../project/MotivoCard'
import Loading from '../layout/Loading'
import {__URL} from '../../config'
import {FaFilter} from 'react-icons/fa'

function Chamados({_viewPort}) {

    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))
    const [chamados, setChamados] = useState([])
    const [removeLoading, setRemoveLoading] = useState(false)
    const [viewPort, setViewPort] = useState(_viewPort || 'list')
    const [viewFilter, setViewFilter] = useState(false)
    const location = useLocation()

    const submitFilter = (formContent) => {

        console.log(formContent);
        let filters = {};

        if ( formContent.status !== undefined )
            filters.status = formContent.status

        if ( formContent.prioridade_interna !== undefined )
            filters.prioridadeInt = (formContent.prioridade_interna);

        if ( formContent.prioridade_externa !== undefined )
            filters.prioridadeExt =  (formContent.prioridade_externa);

        if ( formContent.motivos !== undefined && formContent.motivos.length > 0 )
            filters.motivos = formContent.motivos;

        if ( formContent.autores !== undefined )
            filters.autor = Array.isArray(formContent.autores) ? formContent.autores[0] : formContent.autores;

        if ( formContent.responsaveis !== undefined )
            filters.responsavel = Array.isArray(formContent.responsaveis) ? formContent.responsaveis[0] : formContent.responsaveis;

        if ( formContent.possui_resp !== undefined )
            filters.possui_resp = formContent.possui_resp;

        if ( formContent.data_inicial !== undefined && formContent.data_inicial.length === 10)
            filters.dtAbertInicial = formContent.data_inicial.replaceAll('/', '-');

        if ( formContent.data_final !== undefined && formContent.data_final.length === 10)
            filters.dtAbertFinal = formContent.data_final.replaceAll('/', '-');

        if ( formContent.numero_ticket !== undefined )
            filters.ticketNumero = parseInt(formContent.numero_ticket);

        if ( formContent.conteudo !== undefined && formContent.conteudo.length >= 5 )
            filters.conteudo = formContent.conteudo;

        buscarChamados(setRemoveLoading, setChamados, filters);
    }
    const buscarChamados = (setRemoveLoading, setChamados, filters) => {

        filters = filters === undefined ? {} : filters;

        setChamados([])
        setRemoveLoading(false);
        fetch( __URL + `/chamado/${encodeURI(JSON.stringify(filters))}`, {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            }
        })
        .then(resp => {
            if (resp.status === 200)
                return resp.json()
            return false
        })
        .then((data) => {
            if(data !== false) {
                setChamados(data.data.chamados)
            }
            setRemoveLoading(true)
        })
        .catch((err) => {console.log(err)})

    }

    useEffect(()  => {
         buscarChamados(setRemoveLoading, setChamados, {status : 'A', responsavel : userSession.ID, possui_resp : 'T'})
    }, [setRemoveLoading, setChamados ])

    function toggleViewPort(value) {
        setViewPort(value === 'list' ? 'table' : 'list')
    }

    function toggleViewFilter(value) {
        setViewFilter(!value)
    }

    let message = ''
    let type = 'success'
    if ( location.state ) {
        message = location.state.message
        type = location.state.type !== undefined ? location.state.type : "success"
    }

    let chamadosSemResp = chamados.filter((chm) => {return chm.USUARIOS.length === 1})
    let chamadosComResp = chamados.filter((chm) => {return chm.USUARIOS.length > 1})

    return (
        <div className={styles.project_container}>
            <div className={styles.title_container}>
                <h1>Chamados</h1>
                <div className={styles.btnArea} >
                    {<Button handleOnClick={toggleViewFilter} value={viewFilter} text={`Filtros`} icon={<FaFilter />} />}
                    {<Button handleOnClick={toggleViewPort} value={viewPort} text={`Visualizar em ${viewPort !== 'table' ? 'Cards' : 'Tabela'}`} />}
                </div>
            </div>
            {viewFilter && <ChamadoFilter handleSubmit={submitFilter} />}
            {<Message msg={message} type={type}/>}
                <Container customClass="start">
                    {viewPort === 'table' && chamados.length > 0 &&
                        chamados.map((chamado) => (
                            <ChamadoCard
                            usuarioAutor={chamado.CD_USUARIO_AUTOR}
                            responsaveis={chamado.USUARIOS.filter((usr) => {return parseInt(usr.ATRIBUICAO) === 3})}
                            usuarioFechamento={chamado.CD_USUARIO_FINALIZACAO}
                            id={chamado.ID}
                            motivos={chamado.MOTIVOS}
                            nomeModulo={chamado.TX_MODULO}
                            prioridadeExterna={chamado.PRIORIDADE_EXTERNA}
                            prioridadeInterna={chamado.PRIORIDADE_INTERNA}
                            usuarioInteracao={chamado.CD_USUARIO_ULTIMA_ATV}
                            dataUltimaInteracao={chamado.DT_ULTIMA_ATIVIDADE}
                            grupoEmpresa={chamado.TX_NOME_GRUPO}
                            empresa={chamado.EMPRESA_REQUISITANTE}
                            dataCriacao={chamado.DT_ABERTURA}
                            dataFechamento={chamado.DT_FINALIZACAO}
                            SLAInterna={chamado.SLA_INTERNA_STATUS}
                            SLAExterna={chamado.SLA_EXTERNA_STATUS}
                            />
                        ))}
                    {viewPort === 'list' && chamadosSemResp.length > 0 && <ChamadoTable chamados={chamadosSemResp} titleText='SEM RESPONSÁVEIS' />}
                    {viewPort === 'list' && chamadosComResp.length > 0 && <ChamadoTable chamados={chamadosComResp} titleText='COM RESPONSÁVEIS' />}
               
                    {!removeLoading && <Loading/>}
                    {removeLoading && chamados.length === 0 &&
                        <p>Você não possui chamados no momento.</p>
                    }
                </Container>
        </div>
    )
}

export default Chamados