import {Link} from 'react-router-dom'
import { BsFillPencilFill } from 'react-icons/bs'
import styles from './EditButton.module.css'

function EditButton({to}) {
    return (
        <Link className={styles.btn} to={to}>
            <BsFillPencilFill/>
        </Link>
    )
}

export default EditButton