import styles from './ReplyForm.module.css';
import TextArea from '../form/TextArea';
import SubmitBtn from '../form/SubmitBtn';
import Checkbox from '../form/Checkbox'

import { useState } from 'react'
import InputFile from '../form/InputFile';

function ReplyForm({ handleSubmit, btnText, chamado}) {

    const [replyPost, setReplyPost] = useState({})
    const [encerrar, setEncerrar] = useState('N')
    //const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))

    const submit = (e) => {
        console.log(e.target) 
        e.preventDefault();
        let formData = new FormData(e.target);


        if ( replyPost.reply !== undefined && replyPost.reply.length >= 10 ) {
            formData.append('id_chamado', chamado.ID)
            formData.append('msg_tx_conteudo', replyPost.reply)
            formData.append('chm_encerrar', encerrar)
            handleSubmit(formData)}
            else {
                alert('Preencha o mínimo de 10 caracteres para enviar uma resposta.')
            }
    }

    function handleChange(e) {

        setReplyPost({ ...replyPost, 'reply' : e })
    }

    function handleChangeEncerrar(e) {
        setEncerrar(e.checked ? 'S' : 'N') 

    }

    return (
        <form onSubmit={submit} className={styles.form} method='POST'>
            <div className={styles.chamadoCommentReply}>
                <TextArea handleChange={handleChange} name="reply" />
            </div>
            <div className={styles.reply}>
                <div className={styles.attachment}>
                    <InputFile name='anexos' text='Anexos' placeholder='Insira um Anexo'></InputFile>
                </div>
                <div className={styles.submit}>
                    <Checkbox handleChangeEncerrar={handleChangeEncerrar} name='encerrar' text="Encerrar Chamado? " checked={encerrar} type="encerrar" value='S'></Checkbox>
                    <SubmitBtn text={btnText} hideLabel={true}></SubmitBtn>
                </div>
            </div>
        </form>
    )
}

export default ReplyForm