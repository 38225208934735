import {useNavigate} from 'react-router-dom';
import MotivoForm from '../project/MotivoForm';
import styles from './NovoMotivo.module.css';
import {__URL} from '../../config';
import { useState } from 'react';

function NovoMotivo() {

    const navigate = useNavigate();
    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))

    function createPost(motivo) {
        
            let data = new FormData()
            data.append('mtv_motivo', motivo.TX_MOTIVO)

            fetch( __URL + "/motivo", {
                method: "POST",
                headers : {
                    'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
                },
                body : data
            })
            .then(resp => {
                if (resp.status === 200)
                    return resp.json() 
                return false
            })
            .then(resp => {
            navigate('/motivos', {state:{message : 'Motivo criado com sucesso!'}} )
            })
            .catch(err => { 
                console.log(err)})
            }

        return (
            <div className={styles.newProject_container}>
                <h1>Criar Motivo</h1>
                <p>Crie uma pré-definição de motivo a ser inserido no chamado</p>
                <MotivoForm handleSubmit={createPost} btnText="Criar Motivo" />
            </div>
        )
    }
export default NovoMotivo