import styles from './UsuarioTable.module.css'
import EditButton from '../layout/EditButton';
 
function  UsuarioTable({usuarios}) {

    let quebra = false;
    let trHeader = '';
    return (
        <table className={styles.table}>
            <thead>
            <tr key={0}>
                <th className={styles.tableActionsHead}>#</th>
                <th className={styles.tableIDHead}>ID</th>
                <th className={styles.tableStatusHead}>Status</th>
                <th className={styles.tableNomeHead}>Nome</th>
                <th className={styles.tableUsuarioHead}>Usuário</th>
                <th className={styles.tableEmailHead}>Email</th>
                <th className={styles.tableDTHead}>Data de Criação</th>
            </tr>
            </thead>
            <tbody>
            {usuarios.length > 0 && 
            usuarios.map((usr) => { 
                quebra = usr.TX_NOME_GRUPO != trHeader;
                trHeader = usr.TX_NOME_GRUPO;
                return (
                <>
                {quebra && (<tr><td className={styles.tableGrupoName} colSpan={7}>{usr.CD_GRUPO_EMPRESARIAL > 0 ? `(${usr.CD_GRUPO_EMPRESARIAL}) ${trHeader}` : trHeader}</td></tr>)}
                <tr key={usr.ID}>
                    <td className={styles.tableActions}>
                        <EditButton to={`/usuario/${usr.ID}`}/>
                    </td>
                    <td className={styles.tableID}>{usr.ID}</td> 
                    <td className={styles.tableStatus}>{usr.DT_EXCLUSAO ? 'Inativo' : 'Ativo'}</td>
                    <td className={styles.tableNome}>{usr.TX_NOME}</td> 
                    <td className={styles.tableUsuario}>{usr.CD_USUARIO}</td> 
                    <td className={styles.tableEmail}>{usr.TX_EMAIL}</td> 
                    <td className={styles.tableDTCriacao}>{usr.DT_CRIACAO ? usr.DT_CRIACAO.substr(0, 10) : ''}</td> 
                </tr>
                </>
            )})}
            </tbody>
        </table> 
        )

    }

export default UsuarioTable
