import styles from './Usuarios.module.css';
import {__URL} from '../../config';

import { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';

import Message from '../layout/Message';
import Container from '../layout/Container';
import Button from '../form/Button';
import UsuarioCard from '../project/UsuarioCard';
import UsuarioTable from '../project/UsuarioTable';
import Loading from '../layout/Loading';
import LinkButton from '../layout/LinkButton';


function Usuarios({_viewPort}) {

    const [usuarios, setUsuarios] = useState([])
    const [removeLoading, setRemoveLoading] = useState(false)
    const [usuarioMensagem, setUsuarioMensagem] = useState('')
    const [viewPort, setViewPort] = useState(_viewPort || 'list')
    const [userSession] = useState(JSON.parse(localStorage.getItem('userSession')))

    const location = useLocation();

    useEffect(()  => {
        fetch( __URL + `/usuario`, {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            }
        })
        .then(resp => {
            if (resp.status === 200)
                return resp.json() 
            return false
        })
        .then((data) => {
            if(data !== false) {
                setUsuarios(data.data)
                setRemoveLoading(true)
            }
        }) 
        .catch((err) => {
            console.log(err)})
    }, [userSession])

    function toggleViewPort(value) {
        setViewPort(value == 'list' ? 'table' : 'list')
    }

    function removerUsuario(id) {
        fetch( __URL + `/usuario/${id}`, {
            method : 'DELETE',
            headers : {
                'Content-Type' : 'application/json',
                'Authorization': `Basic ${window.btoa(`${userSession.TX_EMAIL}:${userSession.TX_HASH}`)}`
            },
        })
        .then((resp) => {
            if(resp.status === 200)
                return resp.json()
            return false
        })
        .then((data) => {
            console.log(data)
            if(data !== false) {
                setUsuarios( usuarios.filter( usuario => usuario.ID !== id ))
                setUsuarioMensagem("Usuário inativado com sucesso!")
                setRemoveLoading(true)
                //TODO Mensagem
            } 
        }).catch((err) => {
            console.log('error')
        })
    }

    let message = ''
    if ( location.state )
        message = location.state.message

    return (
        <div className={styles.project_container}>
            <div className={styles.title_container}>
                <h1>Usuários Equipe</h1>
                <div className={styles.btnArea}>

                    <LinkButton to="/usuarios/criar" text="Gerar Usuário" />
                    <Button handleOnClick={toggleViewPort} value={viewPort} text={`Visualizar em ${viewPort !== 'table' ? 'Cards' : 'Tabela'}`} />
                </div>
            </div>
            {message && <Message msg={message} type="success"/>}
            {usuarioMensagem && <Message msg={usuarioMensagem} type="success"/>}
            <Container customClass="start">
                {viewPort == 'table' && usuarios.length > 0 && 
                    usuarios.filter((usr) => {return parseInt(usr.NIVEL)>0}).map((usuario) => (
                        <UsuarioCard
                        id={usuario.ID}
                        nomeUsuario={usuario.TX_NOME}
                        codigoUsuario={usuario.CD_USUARIO}
                        emailUsuario={usuario.TX_EMAIL}
                        statusUsuario={usuario.DT_EXCLUSAO ? 'Inativo' : 'Ativo'}
                        empresa={usuario.TX_NOME_GRUPO}
                        ultimoAcesso={usuario.DT_ULTIMO_ACESSO}
                        handleRemove={removerUsuario}
                        />
                    ))}
                    {viewPort == 'list' && usuarios.length > 0 && (
                        <UsuarioTable usuarios={usuarios.filter((usr) => {return parseInt(usr.NIVEL)>0})}/> 
                    )}
                {!removeLoading && <Loading/>}
                {removeLoading && usuarios.length === 0 &&
                    <p>Não existem usuários no momento.</p>
                }
            </Container>
            <Container customClass="middle">
                <div className={styles.title_container}>
                    <h1>Usuários ELO</h1>
                </div>
            </Container>
            <Container customClass="end">
                {viewPort == 'table' && usuarios.length > 0 && 
                    usuarios.filter((usr) => {return usr.NIVEL === null }).map((usuario) => (
                        <UsuarioCard
                        id={usuario.ID}
                        nomeUsuario={usuario.TX_NOME}
                        codigoUsuario={usuario.CD_USUARIO}
                        emailUsuario={usuario.TX_EMAIL}
                        statusUsuario={usuario.DT_EXCLUSAO ? 'Inativo' : 'Ativo'}
                        empresa={usuario.TX_NOME_GRUPO}
                        handleRemove={removerUsuario}
                        />
                    ))}
                    {viewPort == 'list' && usuarios.length > 0 && (
                        <UsuarioTable usuarios={usuarios.filter((usr) => {return usr.NIVEL === null })}/>
                    )}
                {removeLoading && usuarios.length === 0 &&
                    <p>Não existem usuários no momento.</p>
                }
            </Container>
        </div>
    )
}

export default Usuarios